.verify-loader{
    height: 100px !important;
    width: 100px !important;
}

.login-form {
    width: 100%;
    max-width: 400px;
    padding: 15px;
    margin: auto;
    text-align: center;
    margin-top: calc(50vh - 180px);
}

.err-msg{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
    color:var(--error-text-color);
    font-size: 16px;
    margin-top: 5px;
}
.error-block{
    align-items: center;
    justify-content: space-between;
    gap: 10px;
    color:var(--mdb-danger);
    font-size: 16px;
    margin-top: 5px;
}
.btn-primary {
    background: linear-gradient(144.77deg, #138ee7 -42.01%, #18499c 156.86%);
}
.form-btn {
    border-radius: 20px !important;
    font-size: 15px;
    max-width: 100% !important;
    min-width: 50% !important;
    /* padding: 6px 35px; */
    width: auto !important;
}
.login-bg{
    position: absolute;
    width: 100%;
    margin: 0px auto;
    background: #2b2c2d;
    height: 100vh;
}