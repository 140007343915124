/* @import '~rc-easyui/dist/themes/default/easyui.css';
@import '~rc-easyui/dist/themes/icon.css';
@import '~rc-easyui/dist/themes/react.css'; */
body {
  font-family: Roboto, Helvetica, Arial, sans-serif;
}

.material-symbols-outlined {
  font-variation-settings:
  'FILL' 0,
  'wght' 400,
  'GRAD' 0,
  'opsz' 48
}
.btn{
  box-shadow: none !important;
}

.btn .fas{
  margin-right: 0.5rem;
}

.hover-hand:hover{
  cursor: pointer !important;
}

.ml-2rem{
  margin-left: 1.6rem !important;
}
.fix-width{
  max-width: calc(100% - 40px);
}

.highcharts-credits{
  display: none !important;
}
