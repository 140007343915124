
body {
  color:#6b6f82 !important;
}
table, .list-group-item, .sidebar li a, .input-group-text {
    color:#6b6f82 !important;
  }
.app-container{
    font-family: var(--font-family);
    background-color: var(--bg-color) !important;
}

.loading{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    height: 100vh !important;
    margin-top: calc(50vh - 50px);
}
.loading svg {
    margin: 0 !important;
}

.form-container{
    padding: 1rem;
    width: 100%;
}

/* .card{
    box-shadow: 0px 0px 5px 3px rgb(0 0 0 / 17%) !important;
} */

/* .sidebar {
    box-shadow: 2px 2px 5px 3px rgba(0, 0, 0, 0.155) !important;
} */

/* .navbar{
    box-shadow: 0px 2px 5px 3px rgb(0 0 0 / 23%) !important;
} */

.success-block{
    color: var(--mdb-success);
}