.text-lg{
    /* font-size: 1.125rem; */
    padding-top: 4px;
    font-weight: bold;
}
.btn{
    padding: 5px 10px;
}

.card,
.c-width{
    max-width: calc(100% - 0px);
}
.card.w-100{
    max-width: 100%;
    min-width: 500px;
    height: 300px;
}

.map-img{
    width: 100%;
    height: 80vh;
    object-fit: cover;
    border-radius: 8px;
}
.mr-1{
    margin-right: 0.50rem;
}
.ml-1{
    margin-left: 0.50rem;
}

.highcharts-container svg > rect{
    fill: #ffffff00 !important;
}

.grid-scroll{
    max-height: 50vh;
    overflow-y: auto;
}
.tab-let{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0px 10px;
    /* cursor: pointer; */
    margin-top: -18px;
    margin-left: -19px;
    width: calc(100% + 38px);
    box-shadow: 0px 4px 5px 0px rgb(0 0 0 / 35%) !important;
}
.tablet-active-tab{
    /* glow effect */
    box-shadow: 0px 0px 5px 0px var(--mdb-primary) !important;
    border-radius: 5px;
    color: var(--mdb-primary);
    border-color: var(--mdb-blu) !important;
}
/* .tablet-active-tab::after{
    content: "";
    position: absolute;
    top: 50px;
    left: -2px;
    width: calc(100% + 3px);
    height: 6px;
    background-color: var(--mdb-white);
    border-left: solid 2px;
    border-right: solid 2px;
    border-color: #dfe0e0a8;
} */

.tab-let label{
    height:34px;
}
.tab-let label .material-symbols-outlined{
    position: relative;
    font-size: 18px;
    top: -1px;
}
.tab-let label span.tab-label{
    top: -3px;
    position: relative;
    /* margin-left: 2px; */
    font-size: 13px;
    text-overflow: ellipsis;
    width: 100%;
    height: 23px;
    display: block;
    /* left: 15px; */
    overflow: hidden;
    text-align: center;
    /* line-height: 1; */
    vertical-align: middle;

}

.ant-table-wrapper .ant-table .ant-table-header,
.ant-table-wrapper .ant-table-container table>thead>tr:first-child >*:last-child,
.ant-table-wrapper .ant-table-container table>thead>tr:first-child >*:first-child {
    border-radius: 0 !important;
}

.w-30{
    width: 30%;
}

.nodata-placeholder{
    text-align: center;
    opacity: 0.3;
    margin-top: calc(40vh - 20px);
    margin-bottom: calc(40vh - 20px);
}
