.navbar-nav .dropdown-menu{
    transform: translate3d(-15.8px, 40px, 0px) !important;
}
.navbar-nav .dropdown-menu .dropdown-divider{
    margin: 0 !important;
}
.user-try-container{
    width: 250px;
    height: 150px;
    background-color: var(--mdb-primary);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: #ffffff;
}
.user-try-container .avtor{
    zoom: 2;
    border-color: #ffffff;
}

.user-name{
    font-size: 1.2rem;
    font-weight: bold;
    text-transform: uppercase;
    text-align: center;
}
.user-job{
    text-align: center;
    text-transform: uppercase;
}