.scrollbar {
    transition: all 0.3s ease-in-out;
    /* overflow: hidden; */
    overflow: auto;
}
.scrollbar:hover,
.scrollbar:focus {
  overflow: auto;
}
.scrollbar-primary {
  scrollbar-color: #4285f4 #f5f5f5;
}
.scrollbar-primary::-webkit-scrollbar {
  width: 6px;
  background-color: transparent;
}

.scrollbar-primary::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  background-color: #4285f4;
}

.scrollbar-danger {
  scrollbar-color: #ff3547 #f5f5f5;
}

.scrollbar-danger::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  background-color: transparent;
  border-radius: 10px;
}

.scrollbar-danger::-webkit-scrollbar {
  width: 6px;
  background-color: transparent;
}

.scrollbar-danger::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  background-color: #ff3547;
}

.scrollbar-warning {
  scrollbar-color: #ff8800 #f5f5f5;
}

.scrollbar-warning::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  background-color: transparent;
  border-radius: 10px;
}

.scrollbar-warning::-webkit-scrollbar {
  width: 6px;
  background-color: transparent;
}

.scrollbar-warning::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  background-color: #ff8800;
}

.scrollbar-success {
  scrollbar-color: #00c851 #f5f5f5;
}

.scrollbar-success::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  background-color: transparent;
  border-radius: 10px;
}

.scrollbar-success::-webkit-scrollbar {
  width: 6px;
  background-color: transparent;
}

.scrollbar-success::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  background-color: #00c851;
}

.scrollbar-info {
  scrollbar-color: #33b5e5 red;
}

.scrollbar-info::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  background-color: transparent;
  border-radius: 10px;
}

.scrollbar-info::-webkit-scrollbar {
  width: 6px;
  background-color: transparent;
}

.scrollbar-info::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  background-color: #33b5e5;
}

.scrollbar-default {
  scrollbar-color: #424242d1 #f5f5f5;
}

.scrollbar-default::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  background-color: transparent;
  border-radius: 10px;
}

.scrollbar-default::-webkit-scrollbar {
  width: 6px;
  background-color: transparent;
}

.scrollbar-default::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  background-color: #424242d1;
}

.scrollbar-secondary {
  scrollbar-color: #aa66cc #f5f5f5;
}

.scrollbar-secondary::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  background-color: transparent;
  border-radius: 10px;
}

.scrollbar-secondary::-webkit-scrollbar {
  width: 6px;
  background-color: transparent;
}

.scrollbar-secondary::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  background-color: #aa66cc;
}
