.avtor{
    border-radius: 50%;
    border: 1px solid #555555;
    padding: 0px;
    width: 30px;
    height: 30px;
    margin-left: 5px;
    margin-right: 5px;
    display: inline-block;
    padding-top: 4px;
    font-size: 13px;
    font-weight: bold;
    text-align: center;
}