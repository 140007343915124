.auth-layout,
.layout-box,
.banner{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
    text-align: center;
    width: 100%;
    height: 100vh;
}

.banner{
    height: fit-content;
    width: auto;
}

.banner img{
    margin: 5px;
    width: 300px;
    margin-bottom: 0px !important;
}