.menu-colaps{
    border-radius: 50%;
    padding: 0px;
    font-size: 21px;
    width: 40px;
    height: 40px;
    margin-left: 5px;
}

.menu-colaps:hover{
    background-color: #ffffff15;
}
.rotate-0{
    
    transform: rotate(0deg);
    transition: all 0.5s linear;
}
.rotate-180{
    transform: rotate(180deg);
    transition: all 0.5s linear;
}
h5.nav-title {
    padding-top: 9px;
}
.custome-brand{
    padding: 5px 12px;
    width: 240px;
    background-color: transparent;
    transition: all 0.3s ease-in-out;
}
.custome-brand-mini{
    width: 80px;
}
.custome-brand img{
    transition: all 0.3s ease-in-out;

}
.custome-brand-mini img{
    transition: all 0.3s ease-in-out;
    padding: 5px;
    padding-left: 17px;
}

.navbar {
    box-shadow: 0 2px 30px 2px rgba(0, 0, 0, .1);
    height: 60px !important;
    background: #ffffff;
    z-index: 999;
    
}

.navbar .btn:hover {
    background-color: transparent !important;
}

a.navbar-brand:after{
    display: none;
}
a.navbar-brand {
    width: auto !important;
    justify-content: center !important;
    height: 44px;
}

@media (max-width: 992px) {
    .menu-colaps{
        display: none;
    }

    .custome-brand{
        margin-left: -42px;
        width: 165px;
        padding-left: 54px;
    }
    .navbar-brand {
        height: 44px;
    }

    .navbar-toggler{
        border: 0;
        z-index: 9;
    }
    
    .hide{
        z-index: 0;
    }

    .nav-title{
        display: none;
    }

}
